import React, { useContext, useReducer, useEffect, useState } from "react";
import { View, Pressable, Platform } from "react-native";
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import utils from "../../utils";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useAssets } from "presto-react-components";
import { prestoAlert } from "../../common/Alert";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import NetworkContext from "@presto-contexts/NetworkContext";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";

function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_LOADING":
      return { ...state, loading: payload };
    case "SET_PAYMENT_TYPE":
      return { ...state, paymentType: payload };
    case "SET_FOCUS_ITEM":
      return { ...state, focusedItem: payload };
    case "SET_REFERENCE_ID":
      return { ...state, refId: payload };
    case "SET_SHOW_RECORD_PAYMENT_MODAL":
      return { ...state, showRecordPayment: payload };
    case "SET_ACTION_HANDLER":
      return { ...state, actionHandler: payload };
    case "SET_AMOUNT":
      return { ...state, amount: payload };
    case "SET_PROCEED":
      return { ...state, proceed: payload }
  }
}

export default function PaymentComponent(props) {
  const { svgs } = useAssets();
  const isWeb = Platform.OS == 'web'
  const { Navigator } = useContext(NavigatorContext);
  const {
    cart,
    walletInfo,
    customer,
    paymentOption,
    selectedPaymentOption,
    paymentReferenceId,
    referenceId,
    completeOrder,
    isOfflineCart,
    onPressPaymentMode,
    getTotalPayable,
    balanceAmount,
    setBalanceAmount,
    isQuickCheckout,
    returnCash,
    setReturnCash
  } = props || null;
  const disabled = props.disabled ?? false;
  const { theme } = useContext(ThemeContext);
  const { isOnline } = isWeb ? { isOnline: () => true } : useContext(NetworkContext);
  const styles = getStyles(theme);
  const [selectedPaymentType, setSelectedPaymentType] = useState(undefined);
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    paymentType: "",
    focusedItem: "",
    showRecordPayment: false,
    refId: "",
    actionHandler: null,
    amount: balanceAmount.toString(),
    proceed: false,
  });
  const [cashCollected, setCashCollected] = useState("")
  const enabledOnlinePayments = false;
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();


  const {
    loading,
    paymentType,
    focusedItem,
    showRecordPayment,
    refId,
    actionHandler,
    amount,
    proceed,
  } = state;

  const [wallet, setWallet] = useState(null);

  const setLoading = (value) => {
    dispatch({
      type: "SET_LOADING",
      payload: value,
    });
  };

  useEffect(() => {
    getCustomerWalletInfo();
  }, []);

  useEffect(() => {
    if (actionHandler) {
      triggerActionHandler();
    }
  }, [paymentOption, referenceId, actionHandler]);

  const triggerActionHandler = () => {
    if (actionHandler === "COMPLETE_ORDER") {
      completeOrder();
      dispatch({
        type: "SET_ACTION_HANDLER",
        payload: null,
      });
    }
  };

  const getCustomerWalletInfo = () => {
    const params = {
      userIds: [customer.wallet_id],
    };

    EmployeeManager.userWalletInfo(
      params,
      (response) => {
        setWallet(response.data);
      },
      (error) => {
        console.log("Error here", error);
      }
    );
  };

  const setFocus = (text) => {
    dispatch({
      type: "SET_FOCUS_ITEM",
      payload: text,
    });
  };

  const onFocusLost = () => {
    dispatch({
      type: "SET_FOCUS_ITEM",
      payload: null,
    });
  };

  const onChange = (text, type) => {
    if (type == "SET_AMOUNT") {
      // if ((balanceAmount - Number(text)).toFixed(2) >= 0) {
      dispatch({
        type: "SET_AMOUNT",
        payload: text,
      });
      // } else {
      //   alert(`Amount should be less that or equal to ${balanceAmount}`)
      // }
    }
    else {
      dispatch({
        type: type,
        payload: text,
      });
    }
  };

  const onPressPaymentOption = (type) => {
    setSelectedPaymentType(type);
    selectedPaymentOption(type);
    if (!disabled) {
      dispatch({ type: "SET_PAYMENT_TYPE", payload: type });
      if (isQuickCheckout && type == "CASH") {
        dispatch({
          type: "SET_ACTION_HANDLER",
          payload: "COMPLETE_ORDER",
        });
      } else {
        dispatch({
          type: "SET_SHOW_RECORD_PAYMENT_MODAL",
          payload: true,
        });
      }
    }
  };

  const proceedCondition = () => {
    if (isQuickCheckout) {
      return !amount
    } else {
      return (!amount)
    }
  }

  const recordPaymentReference = () => {
    if (proceedCondition()) {
      alert(I18n.t("error_messages.payments.add_payment_reference_id"));
      return;
    } else {
      setLoading(true);
      setBalanceAmount((balanceAmount - Number(amount)).toFixed(2))
      paymentReferenceId(refId, amount, paymentType);
      dispatch({
        type: "SET_SHOW_RECORD_PAYMENT_MODAL",
        payload: false,
      });
      setReturnCash("");
      setCashCollected("");

      dispatch({
        type: "SET_AMOUNT",
        payload: ""
      })

      dispatch({ type: "SET_REFERENCE_ID", payload: "" });
      if ((balanceAmount - Number(amount)).toFixed(2) == 0) {
        dispatch({
          type: "SET_ACTION_HANDLER",
          payload: "COMPLETE_ORDER",
        });
      }
      setLoading(false);
    }
  };

  const alert = (title, message) =>
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );

  const onDismiss = () => {
    dispatch({
      type: "SET_SHOW_RECORD_PAYMENT_MODAL",
      payload: false,
    });
  };

  const hasSufficientWalletBalance = () => {
    let currentBalance = Number(_.get(walletInfo, "current_balance"));
    let expiryBalance = Number(_.get(walletInfo, "expiry_balance"));

    if (isNaN(currentBalance)) {
      currentBalance = 0;
    }
    if (isNaN(expiryBalance)) {
      expiryBalance = 0;
    }

    let balance = _.add(currentBalance, expiryBalance);
    let totalPrice = Number(cart.total_price);
    if (isNaN(totalPrice)) {
      totalPrice = 0;
    }

    return balance > totalPrice;
  };

  function showRecordPaymentModal() {
    return (
      <ModalMobile
        visible={showRecordPayment}
        onDismiss={onDismiss}
        onConfirmationButtonPress={() =>
          dispatch({
            type: "SET_SHOW_RECORD_PAYMENT_MODAL",
            payload: false,
          })
        }
        modalBackgroundColor={theme.white}
        ModelWidth={(isMobile || isMobileWeb) ? "90%" : "40%"}
        ModelHeight={"50%"}
        modalContainerStyles={{
          alignSelf: "center",
          justifyContent: "center",
          borderRadius: 10,
        }}
        content={
          <LoadingContainer
            loading={loading}
            bgColor={"transparent"}
            style={{ flex: 1 }}
          >
            <View
              style={{
                padding: theme.primaryPadding,
                backgroundColor: theme.white,
              }}
            >
              <View
                style={{
                  padding: 10,
                }}
              >
                {
                  paymentType !== "CASH"
                    ?
                    <>
                      <PrestoText
                        fontStyle="600.semibold"
                        size={14}
                        color={theme.subHeader}
                        extraStyle={{ paddingBottom: theme.primaryPadding }}
                      >
                        {I18n.t("screen_messages.payments.payment_mode")}{" "}
                        <PrestoText
                          fontStyle="600.semibold"
                          size={14}
                          color={theme.black}
                        >
                          {paymentType}
                        </PrestoText>
                      </PrestoText>
                      <PrestoTextInput
                        style={{
                          borderWidth: 2,
                          borderColor:
                            focusedItem === "name" ? theme.primary : "#CCCCCC",
                          textInputPaddingHorizontal: 10,
                        }}
                        theme={theme}
                        placeholder={I18n.t(
                          "screen_messages.payments.payment_ref_id"
                        )}
                        value={refId}
                        keyboardType={"default"}
                        onChange={({ nativeEvent: { text } }) => onChange(text, "SET_REFERENCE_ID")}
                        backgroundColor={theme.white}
                        borderWidth={2}
                        textContentType={"name"}
                        autoCompleteType={"name"}
                        importantForAutofill={"yes"}
                        onFocus={() => setFocus("name")}
                        onBlur={() => onFocusLost()}
                        textAlignVertical="center"
                      />
                    </>
                    : <></>
                }
                <VerticalSpacing size={20} />
                <PrestoText
                  fontStyle="600.semibold"
                  size={14}
                  color={theme.subHeader}
                  extraStyle={{ paddingBottom: theme.primaryPadding }}
                >
                  {I18n.t("screen_messages.amount")}
                </PrestoText>
                <PrestoTextInput
                  style={{
                    borderWidth: 2,
                    borderColor:
                      focusedItem === "name" ? theme.primary : "#CCCCCC",
                    textInputPaddingHorizontal: 10,
                  }}
                  theme={theme}
                  placeholder={I18n.t(
                    "screen_messages.amount"
                  )}
                  value={amount}
                  keyboardType={"numeric"}
                  onChange={({ nativeEvent: { text } }) => {
                    if (cashCollected) {
                      setReturnCash((cashCollected - Number(text)).toFixed(2));
                    }
                    onChange(text, "SET_AMOUNT")
                  }}
                  backgroundColor={theme.white}
                  borderWidth={2}
                  textContentType={"name"}
                  autoCompleteType={"name"}
                  importantForAutofill={"yes"}
                  onFocus={() => setFocus("name")}
                  onBlur={() => onFocusLost()}
                  textAlignVertical="center"
                />
                <VerticalSpacing size={15} />
                {
                  paymentType == "CASH" ?
                    <>
                      <PrestoText
                        fontStyle="600.semibold"
                        size={14}
                        color={theme.subHeader}
                        extraStyle={{ paddingBottom: theme.primaryPadding }}
                      >
                        {I18n.t("screen_messages.cash_collected")}
                      </PrestoText>
                      <PrestoTextInput
                        style={{
                          borderWidth: 2,
                          borderColor:
                            focusedItem === "name" ? theme.primary : "#CCCCCC",
                          textInputPaddingHorizontal: 10,
                        }}
                        theme={theme}
                        placeholder={I18n.t(
                          "screen_messages.cash_collected"
                        )}
                        value={cashCollected}
                        keyboardType={"numeric"}
                        onChange={({ nativeEvent: { text } }) => {
                          console.log(text, "dfjkladisfjoidsjfij")
                          setCashCollected((ps) => Number(text))
                          setReturnCash((Number(text) - amount).toFixed(2));
                        }}
                        backgroundColor={theme.white}
                        borderWidth={2}
                        textContentType={"name"}
                        autoCompleteType={"name"}
                        importantForAutofill={"yes"}
                        onFocus={() => setFocus("name")}
                        onBlur={() => onFocusLost()}
                        textAlignVertical="center"
                      />
                      <VerticalSpacing size={15} /></>
                    : <></>
                }
                {
                  paymentType == "CASH" && returnCash > 0 && cashCollected > 0 ?
                    <PrestoText
                      fontStyle="600.semibold"
                      size={14}
                      color={theme.black}
                      extraStyle={{ paddingBottom: theme.primaryPadding }}
                    >
                      {`${I18n.t("screen_messages.return_cash")}: ${returnCash}`}
                    </PrestoText>
                    : <></>
                }
                <VerticalSpacing size={30} />
                <PrestoSolidButton
                  size="large"
                  buttonStyle="primary"
                  title={I18n.t("screen_messages.payments.record_payment")}
                  titleColor={theme.white}
                  onPress={recordPaymentReference}
                  extraStyle={{
                    padding: theme.primaryPadding,
                  }}
                  disable={proceedCondition() ? true : false}
                  opacity={!proceedCondition() ? 1 : 0.4}
                />
              </View>
            </View>
          </LoadingContainer>
        }
      />
    );
  }

  const BoxItem = ({ title, bgColor, containerStyle = {}, onPress }) => {
    return (
      <View style={{ height: 63, width: "50%", ...containerStyle }}>
        <Pressable
          style={{
            paddingTop: 17,
            textAlignVertical: "center",
            paddingHorizontal: theme.primaryPadding,
            backgroundColor: bgColor,
            borderRadius: 12,
            height: "100%",
          }}
          onPress={onPress}
        >
          <View>
            <PrestoText
              extraStyle={{
                textAlign: "center",
              }}
              fontStyle="600.semibold"
              size={20}
              color={theme.white}
            >
              {title}
            </PrestoText>
          </View>
        </Pressable>
      </View>
    );
  };

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          marginBottom: 10,
        }}
      >
        <BoxItem
          title={I18n.t("screen_messages.payments.cash")}
          bgColor="#F17B8D"
          containerStyle={{ paddingRight: 10 }}
          onPress={() => {
            onPressPaymentOption("CASH");
            if (setReturnCash && setCashCollected) {
              setReturnCash(0);
              setCashCollected(0);
            }
          }}
        />
        <BoxItem
          title={I18n.t("screen_messages.payments.card")}
          bgColor="#5F98A4"
          onPress={() => onPressPaymentOption("CARD")}
        />
      </View>

      {enabledOnlinePayments ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <BoxItem
            title={I18n.t("screen_messages.payments.wallet")}
            bgColor="#77A18B"
            containerStyle={{
              paddingRight: 10,
              opacity:
                isOnline() &&
                  isOfflineEnabledAPI("orderFetch") === false &&
                  isOfflineCart === false &&
                  hasSufficientWalletBalance()
                  ? 1
                  : 0.4,
            }}
            onPress={() => {
              if (
                isOnline() &&
                isOfflineEnabledAPI("orderFetch") === false &&
                isOfflineCart === false &&
                hasSufficientWalletBalance()
              ) {
                onPressPaymentOption("WALLET");
              }
            }}
          />
          <BoxItem
            title={I18n.t("screen_messages.payments.digital")}
            bgColor="#77A18B"
            containerStyle={{
              opacity:
                isOnline() &&
                  isOfflineEnabledAPI("orderFetch") === false &&
                  isOfflineCart === false
                  ? 1
                  : 0.4,
            }}
            onPress={() => {
              if (
                isOnline() &&
                isOfflineEnabledAPI("orderFetch") === false &&
                isOfflineCart === false
              ) {
                onPressPaymentOption("UPI");
              }
            }}
          />
        </View>
      ) : null}

      {showRecordPaymentModal()}
    </View>
  );
}

function getStyles(theme) {
  return {
    buttonStyle: {
      minHeight: 100,
      alignItems: "center",
      justifyContent: "space-evenly",
      width: 400 / 4,
      backgroundColor: theme.white,
      borderWidth: 0.3,
      borderColor: theme.lightdark,
    },
  };
}
