import React, { useEffect, useState, useContext } from 'react';
import { FlatList, Pressable, View } from "react-native";
import MyPurchaseRequests from "@presto-screen-components/PurchaseRequests/MyPurchaseRequests";
import I18n from "i18n-js";
import _ from 'lodash';
import SessionHelper from "@presto-helpers/SessionHelper";
import asyncify from '../../common/Asyncify';
const AsyncSessionHelper = asyncify(SessionHelper);
import SessionManager from "@presto-services/features/session/SessionManager";
import LoginObject from "@presto-services/data_models/LoginObject";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import utils from '../../utils';
import { VerticalSpacing } from "@presto-components/Spacing";
import OrderManager from "@presto-services/features/orders/OrderManager";
import LoginHelper from "@presto-helpers/LoginHelper";
import UserContext from "@presto-contexts/UserContext";
import AsyncStorage from "@react-native-community/async-storage";
import SearchManager from "@presto-services/features/search/SearchManager";
import { useAssets } from "presto-react-components";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import moment from 'moment';
import PurchaseOrdersManager from "@presto-services/features/purchase_orders/PurchaseOrdersManager";

const AsyncEmployeeManager = asyncify(EmployeeManager);
const AsyncPurchaseOrdersManager = asyncify(PurchaseOrdersManager);

const ItemB2BPurchase = (props) => {
  const { activeRow,
    suppliers, tabs,
    isIncomingScreen, purchaseOrderObject,
    loadPurchaseOrders,
    setParentLoading,
    getMasterMerchantId,
    RenderMasterItemHeader,
    onClickAddToStore,
    b2BOrders,
    setB2bOrders,
    setAddInventoryModalVisible
  } = props;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { images, svgs } = useAssets();

  const isMobile = utils.isMobile();
  const [showModal, setShowModal] = useState(false);
  const [showMasterModal, setShowMasterModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [focus, setFocus] = useState("");
  const [orders, setOrders] = useState(null);
  const [allOrders, setAllOrders] = useState(false)
  const [masterItems, setMasterItems] = useState([]);
  const [masterIds, setMasterIds] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedMasterItem, setSelectedMasterItem] = useState([]);

  const merchantId = LoginHelper.getUserMerchantId(user);

  const login = async () => {
    setParentLoading(true)
    let lobj = new LoginObject();
    lobj.user = {
      login: phoneNumber,
      password: password,
    };
    lobj.merchant_id = "64059c725fc4e76ea55e5219";
    lobj.app_id = "ZHC"
    await SessionManager.login(lobj, (response) => {
      setShowModal(false);
      fetchOrders()
      console.log(response);
    }, (err) => {
      setParentLoading(false)
      console.log(err, "lkdjsafijsiofioj")
      alert(err?.message || "Something went wrong")
    });

  }

  const fetchUser = async () => {
    await SessionManager.isLoggedInUser((res) => {
      console.log(res, "jfsdiajfsoijsi")
      fetchOrders()
    }, (err) => {
      setOrders([]);
      setAllOrders([]);
      setShowModal(true);
      setParentLoading(false);
    });
  }

  const fetchOrders = async () => {
    await OrderManager.getMyOrders({}, async (res) => {
      let order = []
      order = res.data?.orders?.map((ele) => ({
        ...ele,
        items: ele.items.map((item) => ({ ...item, purchase_price_value: item?.total_price, purchase_tax_value: item?.total_tax, purchase_quantity: item?.quantity })),
        zipOrder: { status: ele.status, friendly_id: ele?.friendly_id }
      }))
      order = order?.filter((ele) => moment(ele?.created_at).isBetween(moment().subtract("d", 8), moment()));

      let allBatch = await Promise.all(
        order.map(async (pOrder, index) => {
          await Promise.all(
            pOrder?.items.map(async (ele, ind) => {
              if (ele.inventory_batches_info?.length) {
                const params = {
                  ids: ele.inventory_batches_info.map((ids) => ids.id).join(","),
                  inventory_batches_info: ele.inventory_batches_info,
                  merchant_id: user.other_data.permissions.order_merchant_id || "64648cb5180cc982bcad59b7"
                };

                const AsyncManager = AsyncPurchaseOrdersManager;
                try {
                  const response = await AsyncManager.getBatchAndExpiry(params);
                  if (response[1]?.data?.[ele.item_id]?.length > 1) {
                    response[1].data[ele.item_id].forEach((batch) => {
                      pOrder.items.push({
                        isOfBatch: true,
                        ...pOrder.items[ind],
                        batch_number: batch.batch_number,
                        expiry_date: batch.expiry_date,
                        purchase_quantity: batch.initial_quantity,
                        purchase_price_value: batch.purchase_price - pOrder.items[ind].total_tax,
                        purchase_tax_value: pOrder.items[ind].total_tax
                      });
                    });
                  } else {
                    pOrder.items[ind].batch_number = response[1]?.data?.[ele.item_id]?.[0]?.batch_number || "";
                    pOrder.items[ind].expiry_date = response[1]?.data?.[ele.item_id]?.[0]?.expiry_date || ""
                  }
                  // }
                } catch (error) {
                  console.error("Error fetching batch data:", error);
                  throw error;
                }
              }
            })
          );

          return pOrder;
        })
      );

      setOrders(allBatch);

      setAllOrders(res.data?.orders);
      setParentLoading(false)
      let referenceId = [];
      res.data?.orders?.forEach((ele) => {
        ele.items?.forEach((item) => {
          referenceId = [...referenceId, item?.reference_id]
        })
      })
      // getItemsInStoreCatalog(referenceId)
    }, (err) => {
      setParentLoading(false);
      setOrders([]);
      setAllOrders([]);
      console.log(err, "ordererror")
    })
  }

  const inwardItems = async (product, ids, itemB2bList) => {
    setParentLoading(true);
    console.log(product, "DFsdkfsdjfisdjfoijsiojf");
    const id = ids?.find((ele) => ele?.reference_id == product?.reference_id);
    console.log(id, "dfljasdifjiosdjfs")
    await AsyncEmployeeManager.itemPurchaseConfigurationShow({
      id: id.id,
    }).then((res) => {
      product.purchase_quantity = 1;
      product.itemPurchaseConfiguration = res[1]?.data
      console.log(JSON.stringify(res[1]?.data), "dfakljsdifjsidfjsjdof")
    }).catch((err) => console.log(err, "fdlkjsafidjsijsdof"))

    let item = product;
    item.purchase_quantity = 1;
    itemB2bList?.filter((catagoryItemId) => {
      console.log(catagoryItemId, item?.itemPurchaseConfiguration?.category_item_id, "dksjafksdjjsoidjf")
      return (catagoryItemId == item?.itemPurchaseConfiguration?.category_item_id)
    })
    console.log(itemB2bList?.find((catagoryItemId) => catagoryItemId == item?.itemPurchaseConfiguration?.category_item_id), "klsdafjsdfiaosdfd")
    if (item?.itemPurchaseConfiguration) {
      if (!itemB2bList?.find((catagoryItemId) => catagoryItemId == item?.itemPurchaseConfiguration?.category_item_id)) {
        if (item.purchase_quantity) {
          console.log("-- For Item -- ");
          let itemObject = product
          console.log(itemObject);
          let itemPurchaseRequest = itemObject.itemPurchaseRequest;
          const params = {
            inventory_batch: {
              item_id: id.id,
              batch_number: item.batch_number || "NO_BATCH",
              expiry_date: item.expiry,
              initial_quantity:
                parseInt(item.purchase_quantity) *
                parseInt(
                  itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
                ),
              remaining_quantity:
                parseInt(item.purchase_quantity) *
                parseInt(
                  itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
                ),
              purchase_price: item.purchase_price_value,
            },
          };
          console.log("ITEM");
          console.log(params);
          let other_data = item.other_data;
          if (other_data.avg_purchase_price && item.purchase_price_value) {
            let avgpp =
              (parseFloat(other_data.avg_purchase_price) +
                parseFloat(item.purchase_price_value) /
                (parseInt(item.purchase_quantity) *
                  parseInt(
                    itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
                  ))) /
              2;
            other_data.avg_purchase_price = avgpp.toFixed(2);
          } else {
            let avgpp =
              parseFloat(item.purchase_price_value) /
              (parseInt(item.purchase_quantity) *
                parseInt(
                  itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
                ));
            other_data.avg_purchase_price = avgpp.toFixed(2);
          }
          if (item.expiry) {
            other_data.nearest_expiry = moment(item.expiry).format("YYYY-MM-DD");
          }
          other_data.last_purchase_price = (
            parseFloat(item.purchase_price_value) /
            (parseInt(item.purchase_quantity) *
              parseInt(
                itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
              ))
          ).toFixed(2);
          delete other_data["merchant_data"];
          let params1 = {
            category_item: {
              is_available: true,
              name: item.name,
              other_data: JSON.stringify(other_data),
            },
            price: {
              // base_price: item.price.base_price * 100,
              base_price: item?.total_price
            },
          };
          let params2 = {};
          params2.id = id.id;
          params2.body = params1;
          console.log(params2);
          console.log("Update sdfasdfsdf==== ");
          console.log(params);
          await EmployeeManager.updateCategoryItem(
            params2,
            (res) => {
              console.log("Result");
              console.log(res);
            },
            (err) => {
              console.log(err);
              console.log("Error In Update");
            }
          );
          let inwardResponse = {}
          console.log(params, "Dfklajsdfaisodjf")
          await AsyncEmployeeManager.inventoryBatchCreate(params).then(
            async ([error, response]) => {
              console.log("Error -dsdweew ");
              console.log(error);
              console.log(response);
              if (error?.message) {
                alert(error?.message)
              }
              if (!error) {
                inwardResponse = response
                let itemPurchaseData = await AsyncStorage.getItem(
                  "ITEM_PURCHASE_LIST_B2B"
                );

                if (!itemPurchaseData || itemPurchaseData == "undefined") {
                  itemPurchaseData = "[]";
                }
                let itemPurchases = JSON.parse(itemPurchaseData)
                itemPurchases = [...itemPurchases, response?.data?.category_item_id]
                itemPurchases = JSON.stringify(_.uniq(itemPurchases));
                console.log(itemPurchaseData, itemPurchases, "flkjdasifjidofj")
                await AsyncStorage.setItem("ITEM_PURCHASE_LIST_B2B", itemPurchases)
                console.log("ITEM_PURCHASE_LIST_B2B", [...itemPurchases, response?.data?.category_item_id], "dkfjajfsiojoisdjf")
                setParentLoading(false)
              } else {
                setParentLoading(false);
                alert("Some thing went wrong")
              }
            }
          );
        }
      } else {
        setParentLoading(false)
        alert("Already inwarded")
      }
    }
  }

  const getItemsInStoreCatalog = async (ids, orders, callBack) => {
    let params = {
      // merchant_id: merchantId,
      ref_ids: ids,
    };
    let itemListB2b = await AsyncStorage.getItem(
      "ITEM_PURCHASE_LIST_B2B"
    )
    if (!itemListB2b || itemListB2b == "undefined") {
      itemListB2b = "[]"
    }
    itemListB2b = JSON.parse(itemListB2b);

    return SearchManager.searchCategoryItem(
      params,
      (response) => {
        const responseItems = response?.hits?.hits;
        const responseRefItems = responseItems?.map((ele) => ele?._source.reference_id);
        const itemIds = responseItems?.map((ele) => ({ id: ele?._source.id, reference_id: ele?._source.reference_id }));
        const setResponseItems = new Set(responseRefItems);
        let idsToMaster = [];
        let idsFromStore = [];
        ids.forEach((id) => {
          if (!setResponseItems.has(id)) {
            idsToMaster.push(id)
          } else {
            let storeId = responseItems?.find((ele) => id == ele?._source.reference_id)
            idsFromStore.push({ id: storeId?._source.id, reference_id: storeId?._source.reference_id })
          }
        })
        console.log(idsToMaster, idsFromStore, "dfsjaiosjdfiojisdf")
        setMasterIds(idsToMaster)
        if (idsToMaster?.length) {

          const masterCallback = () => {
            let isValid = callBack(orders)
            console.log("INWARD item")
            if (isValid) {
              let items = orders?.items || orders;
              // console.log(item, "dlkjsfaisdjfoiaj")
              if (items?.length) {
                items?.forEach(async (ele) => {
                  console.log(JSON.stringify(ele), itemListB2b, "djafsidjfaoijsdfjiodj")
                  await inwardItems(ele, idsFromStore, itemListB2b);
                })
              } else {
                inwardItems(items, idsFromStore, itemListB2b);
              }
            }
          }

          fetchItemsFromMaster(idsToMaster, idsFromStore, masterCallback)
        } else {
          let isValid = callBack(orders)
          console.log("INWARD item")
          if (isValid) {
            let items = orders?.items || orders;
            // console.log(item, "dlkjsfaisdjfoiaj")
            if (items?.length) {
              items?.forEach(async (ele) => {
                console.log(JSON.stringify(ele), itemListB2b, "djafsidjfaoijsdfjiodj")
                await inwardItems(ele, itemIds, itemListB2b);

              })
            } else {
              inwardItems(items, itemIds, itemListB2b);
            }
          }
        }
      },
      (err) => console.log((err), "fojasdjfaijosj")
    );
  };

  const getItemsFromMaster = async (params, success, error) => {
    return await SearchManager.searchCategoryItem(params, (res) => success(res), (err) => error(err))
  }

  const fetchItemsFromMaster = (ids, idsFromStore, callBack) => {
    const params = {
      category_id: "",
      // page: 25,
      merchant_id: getMasterMerchantId(),
      ref_ids: ids,
    };
    return getItemsFromMaster(params,
      (response) => {
        console.log(JSON.stringify(response), "kdsjfaisdjfiaojsdofja");
        setMasterItems(response?.hits?.hits?.map((ele) => ({ ...ele?._source, is_available: false })));
        if (response?.hits?.hits?.length) {
          setShowMasterModal(true);
        } else {
          if (idsFromStore?.length) {
            alert("Cannot inward some items")
            callBack()
          }
        }
      },
      (err) => {
        console.log(err, "jsfiodjadsiofjoijsdofiaj")
      }
    )
  }


  const onDismiss = () => {
    setShowModal(!showModal)
  };

  const masterCatalogModal = () => {
    return (
      <ModalMobile
        visible={showMasterModal}
        onDismiss={() => { setShowMasterModal(!showMasterModal) }}
        onConfirmationButtonPress={() =>
          setShowMasterModal(false)
        }
        modalBackgroundColor={theme.white}
        ModelWidth={isMobile ? "90%" : "90%"}
        ModelHeight={"50%"}
        modalContainerStyles={{
          alignSelf: "center",
          justifyContent: "center",
          borderRadius: 10,
        }}
        content={<View
          style={{
            padding: theme.primaryPadding,
            backgroundColor: theme.white,
          }}>
          <RenderMasterItemHeader />
          {modalLoading ?
            <PrestoText>Loading...</PrestoText>
            : <FlatList
              renderItem={({ item }) => {
                console.log(JSON.stringify(item), "fiojasdiofajisodjfiosjdif")
                return (
                  <View style={{ width: "100%" }}>
                    <ItemCard
                      item={item}
                      selected={null}
                      onPress={() => null}
                      onPressButton={() => {
                        setSelectedMasterItem((ps) => [...ps, item])
                        onClickAddToStore(item, true);
                      }
                      }
                      onLongPress={null}
                      tabText={"Master Catalog"}
                      forMasterCatalog={isMobile}
                      zipImage={images.ZipIcon}
                    />
                  </View>
                )
              }}
              data={masterItems}
            />}
        </View>}
      />
    )
  }

  const showLoginModal = () => {
    return (
      <ModalMobile
        visible={showModal}
        onDismiss={onDismiss}
        onConfirmationButtonPress={() =>
          setShowModal(false)
        }
        modalBackgroundColor={theme.white}
        ModelWidth={isMobile ? "90%" : "40%"}
        ModelHeight={"50%"}
        modalContainerStyles={{
          alignSelf: "center",
          justifyContent: "center",
          borderRadius: 10,
        }}
        content={
          <View
            style={{
              padding: theme.primaryPadding,
              backgroundColor: theme.white,
            }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <PrestoText>{I18n.t(
                "screen_messages.login_title"
              )}</PrestoText>
              <Pressable onPress={onDismiss}>
                <PrestoText>
                  X
                </PrestoText>
              </Pressable>
            </View>
            <VerticalSpacing size={15} />
            <PrestoText>{I18n.t(
              "screen_messages.phone_number"
            )}</PrestoText>
            <PrestoTextInput
              style={{
                borderWidth: 2,
                borderColor:
                  focus === "phoneNumber" ? theme.primary : "#CCCCCC",
                textInputPaddingHorizontal: 10,
              }}
              theme={theme}
              placeholder={I18n.t(
                "screen_messages.phone_number"
              )}
              value={phoneNumber}
              keyboardType={"default"}
              onChange={({ nativeEvent: { text } }) => setPhoneNumber(text)}
              backgroundColor={theme.white}
              borderWidth={2}
              textContentType={"phoneNumber"}
              autoCompleteType={"phoneNumber"}
              importantForAutofill={"yes"}
              onFocus={() => setFocus("phoneNumber")}
              onBlur={() => setFocus(null)}
              textAlignVertical="center"
            />
            <VerticalSpacing size={15} />
            <PrestoText>{I18n.t(
              "screen_messages.password_placeholder"
            )}</PrestoText>
            <PrestoTextInput
              style={{
                borderWidth: 2,
                borderColor:
                  focus === "password" ? theme.primary : "#CCCCCC",
                textInputPaddingHorizontal: 10,
              }}
              theme={theme}
              placeholder={I18n.t(
                "screen_messages.password_placeholder"
              )}
              value={password}
              keyboardType={"default"}
              onChange={({ nativeEvent: { text } }) => setPassword(text)}
              backgroundColor={theme.white}
              borderWidth={2}
              textContentType={"password"}
              autoCompleteType={"password"}
              importantForAutofill={"yes"}
              onFocus={() => setFocus("password")}
              onBlur={() => setFocus(null)}
              textAlignVertical="center"
            />
            <VerticalSpacing size={20} />
            <PrestoSolidButton
              size="large"
              buttonStyle="primary"
              title={I18n.t("screen_messages.login_title")}
              titleColor={theme.white}
              onPress={() => login()}
              extraStyle={{
                padding: theme.primaryPadding,
                paddingVertical: 11
              }}
              disable={(!phoneNumber || !password) ? true : false}
              opacity={(phoneNumber && password) ? 1 : 0.4}
            />
          </View>}
      />
    )
  }

  useEffect(() => {
    setParentLoading(true)
    fetchUser()
  }, []);

  useEffect(() => {
    setModalLoading(true);
    if (b2BOrders) {
      setModalLoading(true);
      setB2bOrders(false)
      if (masterIds?.length) {
        setModalLoading(true);
        setMasterItems([]);
        const params = {
          category_id: "",
          // page: 25,
          merchant_id: getMasterMerchantId(),
          ref_ids: masterIds,
        }
        getItemsFromMaster(params, (response) => {
          console.log(JSON.stringify(selectedMasterItem), "kdsjfaisdjfiaojsdofja");
          let items = response?.hits?.hits?.map((ele) => {
            return {
              ...ele?._source, is_available:
                selectedMasterItem?.find((masterItem) => {
                  return masterItem?.reference_id == ele?._source?.reference_id
                })?.id ? true : false
            }
          });
          console.log(JSON.stringify(items), "Dfjasdfsjidfjaiojsdfjoj")
          items?.filter((ele) => {
            console.log(ele?.is_available, "flkjasdafjiodf")
            return ele?.is_available
          })
          if (items?.filter((ele) => ele?.is_available)?.length == items?.length) {
            setSelectedMasterItem([]);
            setShowMasterModal(false);
            alert("Continue with Inward")
          };
          setMasterItems(items);
          setModalLoading(false)
        },
          (err) => {
            setModalLoading(false)
            console.log(err, "jsfiodjadsiofjoijsdofiaj")
          })
      }
    }
    setModalLoading(false);
  }, [b2BOrders, masterIds])

  return (
    <View style={{ height: "100%", width: "100%" }}>
      {
        allOrders?.length == 0
          ? <View style={{ flex: 1, justifyContent: 'center', alignItems: "center", height: "100%", width: "100%" }}>
            <PrestoSolidButton
              size="large"
              buttonStyle="primary"
              title={I18n.t("screen_messages.login_title")}
              titleColor={theme.white}
              onPress={onDismiss}
              extraStyle={{
                padding: theme.primaryPadding,
                paddingVertical: 11
              }}
            />
          </View>
          : !orders?.length == 0 ?
            <MyPurchaseRequests
              showClosed={true}
              activeRow={activeRow}
              suppliers={suppliers}
              column2Title={
                _.some(tabs, ["id", "awaiting_approval"])
                  ? I18n.t("screen_messages.orders.items")
                  : I18n.t("screen_messages.suppliers.title2")
              }
              onPressActionButton={() => { }}
              onPressRow={(tempPurchaseOrder) => {
              }}
              zipB2bOrderItems={(items, callBack) => {
                getItemsInStoreCatalog([items?.reference_id], items, callBack)
              }}
              zipB2bOrders={(order, callBack) => {
                let referenceId = [];
                order.items?.forEach((item) => {
                  referenceId = [...referenceId, item?.reference_id]
                })

                getItemsInStoreCatalog(referenceId, order, callBack)

              }}
              items={orders}
              onEndReached={() => { }}
              isIncomingScreen={isIncomingScreen}
              purchaseOrderObject={purchaseOrderObject}
              loadPurchaseOrders={loadPurchaseOrders}
            />
            : <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <PrestoText>{I18n.t("screen_messages.no_orders")}</PrestoText>
            </View>
      }
      {showLoginModal()}
      {masterCatalogModal()}
    </View>
  )
}

export default ItemB2BPurchase
